// import '../styles/globals.css'
import { register } from "swiper/element/bundle";
import type { AppProps } from "next/app";
import "../styles/global.scss";
import { appWithTranslation } from "next-i18next";

register();

function MyApp({ Component, pageProps }: AppProps) {
  return <Component {...pageProps} />;
}

export default appWithTranslation(MyApp);
